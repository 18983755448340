import React from 'react';
import bannerImage from '../assets/DSC03995.JPG';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    image: require('../assets/DSC04063.JPG'),
    text: "Get a closer look at the textures and colors to make well-informed decisions for your home.",
    isEvenSection: false,
  },
  {
    image: require('../assets/DSC04004.JPG'),
    text: "Use our samples to find the ideal flooring that will transform your space.",
    isEvenSection: true,
  },
];


const OrderSample = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Order Samples"
      sections={sections}
    />
  );
};

export default OrderSample;


