import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';

const ResponsiveTypography = ({ title, text, maxFont }) => {
  const [fontSize, setFontSize] = useState('inherit');
  const titleRef = useRef(null);

  const calculateFontSize = (element, { minFontSize, maxFontSize }) => {
    let start = minFontSize, end = maxFontSize, optimalFontSize;

    while (start <= end) {
      optimalFontSize = (start + end) >> 1;
      element.style.fontSize = `${optimalFontSize}px`;

      if (element.scrollWidth <= element.parentNode.offsetWidth) {
        start = optimalFontSize + 1;
      } else {
        end = optimalFontSize - 1;
      }
    }

    return `${Math.max(minFontSize, end)}px`;
  };

  useEffect(() => {
    const updateFontSize = () => {
      if (titleRef.current) {
        const newFontSize = calculateFontSize(titleRef.current, {
          minFontSize: 16,
          maxFontSize: maxFont || 230
        });
        setFontSize(text?(newFontSize/2):newFontSize);
      }
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);

    return () => window.removeEventListener('resize', updateFontSize);
  }, [title]);

  return (
    <Typography
      ref={titleRef}
      variant="h1"
      sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: (text ? '' : 'uppercase'),
        color: 'white',
        width: '100%',
        padding: '0',
        margin: '0',
        fontSize: fontSize
      }}
    >
      {title}{text}
    </Typography>
  );
};

export default ResponsiveTypography;
