import React from "react";
import { Container, Typography, Link, Box } from "@mui/material";
import Logo from "../assets/grayscale_transparent.png";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "#020003",
        color: "white",
        p: 4,
        bottom: 0,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'space-between' },
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            textAlign: { xs: 'center', md: 'left' },
            flexWrap: 'wrap',
            '& > *:not(:last-child)': {
              mb: { xs: 2, md: 0 },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'center' },
              '& img': {
                mb: { xs: 2, sm: 0 },
                mr: { xs: 0, sm: 2 },
              },
            }}
          >
            <img src={Logo} alt="Alben Hardwood Flooring" style={{ height: '200px' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'center', sm: 'flex-end' },
              '& a': {
                textAlign: 'center',
                mb: { xs: 1, sm: 0 },
                ml: { sm: 2 },
              },
            }}
          >
            <Link href="/contact-us" color="inherit" underline="hover">
              Contact Us
            </Link>
            <Link href="/hardwood-flooring" color="inherit" underline="hover">
              Hardwood Floors
            </Link>
            <Link href="/flooring-resources" color="inherit" underline="hover">
              Flooring Resources
            </Link>
            <Link href="/about-alben" color="inherit" underline="hover">
              About Us
            </Link>
          </Box>
        </Box>

        <Typography variant="caption" display="block" textAlign="center" mt={3}>
          © 2023 Alben Hardwood Flooring. All rights reserved.
        </Typography>
        <Typography variant="caption" display="block" textAlign="center" mt={1}>
          For more information, review our
          <Link href="/privacy-policy" color="inherit" underline="hover" sx={{ mx: 0.5 }}>
            Privacy Policy
          </Link>
          and
          <Link href="/terms-conditions" color="inherit" underline="hover" sx={{ mx: 0.5 }}>
            Terms & Conditions.
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
