import React, { useState } from 'react';
import PageLayout from '../components/PageLayout';
import image2 from '../assets/customer-floor-1.jpg';
import image1 from '../assets/00051-3062451665.png';
import image3 from '../assets/DSC03942.JPG';
import heroImage from '../assets/DSC03987.JPG';


const homepageContent = {
  videoBanner: {
    src: 'https://www.youtube.com/watch?v=cNf0hJ5pABE',
    title: 'Alben Hardwood Flooring',
    subtitle: 'Your Source of Distinct, Local, and High-Quality Hardwood Flooring',
  },
  sections: [
    {
      image: image1,
      text: 'Premium Hardwood Flooring',
      buttonText: 'View Our Products',
      buttonLink: '/products',
    },
    {
      image: image2,
      text: 'Crafted with Precision',
      buttonText: 'Explore The Options',
      buttonLink: '/products',
    },
    {
      image: image3,
      text: 'Diverse and Unique Selection',
    }
  ],
};

const Homepage = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const handleVideoLoaded = () => setVideoLoaded(true);

  const sections = homepageContent.sections.map((section, index) => ({
    ...section,
    offset: index,
  }));

  return (
      <PageLayout
        heroTitle="Alben Hardwood Flooring"
        heroVideo='https://www.youtube.com/watch?v=cNf0hJ5pABE'
        sections={sections}
      />
  );
};

export default Homepage;
