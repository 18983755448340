import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Footer from "./Footer";
import ResponsiveTypography from "./ResponsiveTypography";

const ContentLayout = ({ heroImage, heroTitle, children }) => {
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const heroOpacity = Math.max(1 - scrollY / 500, 0);

    return (
        <>
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100vh",
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "opacity 0.3s",
                    opacity: heroOpacity,
                    zIndex: -1,
                }}
            >
                <ResponsiveTypography title={heroTitle} />
            </Box>

            <Box sx={{ height: "100vh" }} />

            <Box sx={{ width: "100%", position: "relative", zIndex: 1, margin: "32px" }}>
                {children}
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    bottom: 0,
                    width: '100%',
                    zIndex: 2,
                }}
            >
                <Footer />
            </Box>
        </>
    );
};

export default ContentLayout;
