import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  CardContent
} from "@mui/material";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import bannerImage from "../assets/DSC04062.JPG";
import ContentLayout from "../components/ContentLayout";

// Firebase configuration and initialization
const firebaseConfig = {
  apiKey: "AIzaSyBPvmmO5wCR9BJViHeMwzmlRfEzouwbp7A",
  authDomain: "alben-hardwood.firebaseapp.com",
  projectId: "alben-hardwood",
  storageBucket: "alben-hardwood.appspot.com",
  messagingSenderId: "908769836915",
  appId: "1:908769836915:web:70d8cf67ca54d3573f0f06",
  measurementId: "G-W5LPQ9QTEW"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const contactSections = [
  {
    title: "Get in Touch",
    description: "We are here to answer any questions you may have. Reach out to us and we will respond as soon as we can."
  }
];

const ContactUs = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!firstName.trim() || !lastName.trim() || !email.trim() || !message.trim()) {
      setSnackbarMessage("All fields are required.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }
    if (!recaptchaValue) {
      setSnackbarMessage("Please verify that you are not a robot.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }
    if (!validateEmail(email)) {
      setSnackbarMessage("Please enter a valid email address.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "contact"), {
        firstName,
        lastName,
        email,
        message,
        recaptchaValue,
        createdAt: new Date()
      });
      setSnackbarMessage("Your message has been sent successfully. Document ID: " + docRef.id);
      setSnackbarSeverity("success");
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error adding document: ", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setRecaptchaValue(null);
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const contactForm = !formSubmitted && (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 0, py: 4 }}>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Message" variant="outlined" multiline rows={4} value={message} onChange={(e) => setMessage(e.target.value)} />
          </Grid>
        </Grid>
        { !isDevelopment && <GoogleReCaptcha onVerify={onRecaptchaChange} /> }
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </form>
  );

  const renderSections = () => contactSections.map((section, index) => (
    <Box key={index}>
      <Typography variant="h4" gutterBottom>
        {section.title}
      </Typography>
      <Typography>
        {section.description}
      </Typography>
    </Box>
  ));

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdT4ccpAAAAAKlLeDT9RK13ibXpbJ0xPsc02U0O">
      <ContentLayout heroTitle="Contact Us" heroImage={bannerImage}>
        <Container maxWidth="md">
          <Box sx={{ boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px' }}>
            <Card sx={{zIndex: 2}}>
              <CardContent>
                {renderSections()}
                {contactForm}
                {formSubmitted && (
                  <Typography variant="h5" sx={{ mt: 3 }}>
                    Thank you for contacting us. We will get back to you soon.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Container>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </ContentLayout>
    </GoogleReCaptchaProvider>
  );
};

export default ContactUs;
