import React from 'react';
import { Container, Box } from '@mui/material';
import bannerImage from '../assets/IMG_3140.jpeg';
import ContentLayout from '../components/ContentLayout';

const AboutAlben = () => {
  const videoSrc = 'https://www.youtube.com/embed/cNf0hJ5pABE?autoplay=0&showinfo=1&loop=0';

  return (
    <ContentLayout heroTitle="About Us" heroImage={bannerImage}>
      <Container maxWidth="lg">
        <Box sx={{ width: '100%', position: 'relative', paddingTop: '56.25%', zIndex: 2 }}>
          <iframe
            src={videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}
            title="YouTube video player"
          />
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default AboutAlben;
