import React from 'react';
import bannerImage from '../assets/DSC04032.JPG';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    image: require('../assets/DSC04075.JPG'),
    text: "At Alben Hardwood Flooring, excellence is the result of a meticulous process.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC04114.JPG'),
    text: "From responsibly sourcing the finest hardwoods to the craftsmanship that transforms them into exquisite flooring, our commitment to perfection is evident at every step.",
    isEvenSection: false,
  },
  {
    image: require('../assets/customer-floor-3.jpg'),
    text: "Discover the dedication behind our process and why Alben Hardwood Flooring is the choice of discerning homeowners.",
    isEvenSection: true,
  },
];


const OurProcess = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Our Process"
      sections={sections}
    />
  );
};

export default OurProcess;


