import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  Divider,
  ListSubheader,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/grayscale_transparent_nobuffer.png';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  const navLinks = [
    { label: "About", path: "/about-alben" },
    { label: "Hardwood Flooring", path: "/hardwood-flooring" },
    { label: "Walls and Ceilings", path: "/walls-and-ceilings" },
    { label: "Contact", path: "/contact-us" },
  ];

  const sideNavSections = [
    {
      title: "Company Info",
      links: [
        { name: "About Alben", path: "/about-alben" },
        { name: "History", path: "/history" },
        { name: "Our Process", path: "/our-process" },
        { name: "Contact Us", path: "/contact-us" },
      ],
    },
    {
      title: "Products",
      links: [
        { name: "Hardwood Flooring", path: "/hardwood-flooring" },
        { name: "Accessories", path: "/accessories" },
        { name: "Order Sample", path: "/order-sample" },
        { name: "Featured Products", path: "/featured-products" },
        { name: "Walls and Ceilings", path: "/walls-and-ceilings" },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Installation and Care", path: "/installation-and-care" },
        { name: "How To Clean", path: "/how-to-clean" },
        { name: "Room Estimator", path: "/room-estimator" },
      ],
    },
    {
      title: "Visuals",
      links: [{ name: "Gallery", path: "/gallery" }],
    },
    {
      title: "Guides",
      links: [
        { name: "Introduction", path: "/introduction" },
        { name: "Wood Types", path: "/wood-types" },
      ],
    },
  ];

  const isSelected = (path) => location.pathname === path;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ background: 'white', py: 2, boxShadow: 'none' }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Button
            component={Link}
            to={'/'}
            sx={{ display: 'flex', alignItems: 'center', color: 'black' }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '120px' }} />
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {navLinks.map((link, index) => (
              <Button
                key={link.path}
                component={Link}
                to={link.path}
                sx={{
                  color: 'black',
                  border: 0,
                  borderRadius: 0,
                  borderBottom: isSelected(link.path) ? 2 : 0,
                  borderColor: isSelected(link.path) ? 'currentColor' : 'transparent',
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                {link.label}
              </Button>
            ))}
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon style={{ color: 'black' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <Box
          sx={{ width: 250, padding: 2 }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          {sideNavSections.map((section, index) => (
            <React.Fragment key={section.title}>
              {index !== 0 && <Divider />}
              <List
                subheader={
                  <ListSubheader component="h5" sx={{fontSize:".75em", lineHeight: "1em"}}>
                    {section.title.toUpperCase()}
                  </ListSubheader>
                }
              >
                {section.links.map((link) => (
                  <ListItem
                    button
                    key={link.path}
                    component={Link}
                    to={link.path}
                    sx={{ textAlign: 'left' }}
                  >
                    {link.name}
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default Navbar;
