import React from 'react';
import DetailPageLayout from '../components/DetailPageLayout';

// Ensure you have the correct paths to your images
import sawmillImage from '../assets/DSC04129.JPG';
import installationImage from '../assets/00028-2862863702.png';
import forestImage from '../assets/00048-63198419.png';

const sections = [
  {
    title: "From Forest to Floor",
    subheader: "Sustainable and Local",
    content: "Alben Hardwood Floors began with the synergy of sawmill tradition and flooring craftsmanship. For generations, our family-operated business has been sustainably harvesting and transforming locally sourced trees into exquisite hardwood floors.",
    image: forestImage, // replace with an actual image of your local forest and lumber
  },
  {
    title: "The Art of Woodworking",
    subheader: "Generational Expertise",
    content: "Our legacy is built on the deep-rooted expertise passed down through family lines. At Alben, woodworking is not just a process; it's an art form. We're proud to bring together generations of sawmill and flooring installation mastery to create floors that are as beautiful as they are durable.",
    image: sawmillImage, // replace with an actual image showing the art of woodworking or your facilities
  },
  {
    title: "A Commitment to Quality",
    subheader: "Family Owned, Customer Loved",
    content: "Being family-owned and operated means we put our heart into every floor, ensuring each one meets our high standards of quality and sustainability. We don't just sell floors; we're crafting the canvas for your home's future memories.",
    image: installationImage, // replace with an actual image of a family or team that represents your business
  },
];

const History = () => {
  return (
    <DetailPageLayout
      pageTitle="Our Rich History"
      headerImage={sawmillImage}
      sections={sections}
    />
  );
};

export default History;