import React from 'react';
import { Typography, Divider, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ContentLayout from './ContentLayout'; // Import the ContentLayout

const DetailPageLayout = ({ pageTitle, headerImage, sections }) => {

  const handleOnLoad = () => {
    // handle the load event
  };

  return (
    <ContentLayout heroImage={headerImage} heroTitle={pageTitle}>
      {sections.map((section, index) => (
        <Box key={index}>
          <Divider sx={{ mb: 4 }} />
          <Box
            display="flex"
            flexDirection={section.image ? (index % 2 === 0 ? 'row' : 'row-reverse') : 'column'}
          >
            <Box flex={1} sx={{ p: 3 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {section.title}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {section.subheader}
              </Typography>
              <Typography variant="body1">{section.content}</Typography>
            </Box>
            {section.image && (
              <Box
                flex={1}
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LazyLoadImage
                  src={section.image}
                  alt={section.title}
                  effect="blur"
                  onLoad={handleOnLoad}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    borderRadius: '4px',
                    opacity: 1,
                    position: 'relative',
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </ContentLayout>
  );
};

export default DetailPageLayout;
