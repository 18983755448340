import React from 'react';
import DetailPageLayout from '../components/DetailPageLayout';

// Importing images for each wood type
import whiteOakImage from '../assets/whiteOak2.png';
import redOakImage from '../assets/redOak2.png';
import hickoryImage from '../assets/Hickory.png';
import mahoganyImage from '../assets/cherry.png';
import livingRoom from '../assets/IMG_3140.jpeg'
// Add more images as needed

const sections = [
  {
    title: "White Oak",
    subheader: "Versatile and Durable",
    content: "White Oak is renowned for its durability, moisture resistance, and tight grain. It's a versatile choice, perfect for various design styles. Available in widths ranging from 2.25” to 8” and lengths of 1-10 feet.",
    image: whiteOakImage,
  },
  {
    title: "Red Oak",
    subheader: "Warm and Traditional",
    content: "Red Oak, with its warm tones and distinct grain, is a traditional choice that adds a touch of warmth to any interior design. Available in widths of 2.25” to 8” and lengths of 1-10 feet.",
    image: redOakImage,
  },
  {
    title: "Hickory",
    subheader: "Strong and Unique",
    content: "Hickory offers distinctive looks with considerable durability. Its strength and unique patterns make it stand out. Available in widths from 3” to 8” and lengths of 1-10 feet.",
    image: hickoryImage,
  },
  {
    title: "Cherry",
    subheader: "Luxurious and Refined",
    content: "Cherry is prized for its beauty and lasting durability. It's ideal for high-end applications, offering a luxurious and refined look. Available in widths of 3” to 8” and lengths of 1-10 feet.",
    image: mahoganyImage,
  },
  // Add more sections for additional wood types with their respective images and specifications
];

const WoodTypes = () => {
  return (
    <DetailPageLayout
      headerImage={livingRoom}
      pageTitle="Our Wood Types"
      sections={sections}
    />
  );
};

export default WoodTypes;
