import React from 'react';
import DetailPageLayout from '../components/DetailPageLayout';

const termsAndConditionsContent = `Terms and Conditions for AlbenHardwoodFlooring.com

1. Introduction
Welcome to AlbenHardwoodFlooring.com (the “Website”), a digital platform dedicated to providing high-quality hardwood flooring products and services. These Terms and Conditions govern your access to and use of the Website, as well as any related services provided by Alben Hardwood Flooring located in Portersville, PA (“we”, “us”, or “our”).

By accessing the Website, you confirm that you have read, understood, and agree to be bound by these Terms, including our Privacy Policy, without modification. If you do not agree to these Terms, you must not use our Website.

2. Use of Website
The Website is provided for your personal and non-commercial use, subject to the following conditions:

You are of legal age to form a binding contract with Alben Hardwood Flooring.
You will use the Website only for lawful purposes and in accordance with these Terms.
You will not use the Website in any way that could damage, disable, overburden, or impair the site or interfere with any other party's use of the Website.
All purchases through our Website or other transactions for the sale of goods formed through the Website are governed by our Terms of Sale, which are hereby incorporated into these Terms.
3. Intellectual Property Rights
The content, arrangement, and layout of the Website, including, but not limited to, the trademarks, photos, logos, text, graphics, and software, are the property of Alben Hardwood Flooring, or its licensors, and are protected by copyright and intellectual property laws. You may not use such material except as provided in these Terms.

4. User Accounts
If you create an account on the Website, you are responsible for maintaining the confidentiality of your account and password, and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.

5. Product Information
We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available on the Website. However, we do not guarantee that the colors, features, specifications, and details will be accurate, complete, reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the products.

6. Terms of Sale
All purchases made through the Website are subject to product availability. We reserve the right to discontinue any product at any time. All prices for products are subject to change without notice.

7. Cancellations, Returns, and Refunds
Please refer to our Return Policy for details about cancellations, returns, and refunds.

8. Limitation of Liability
To the fullest extent permitted by applicable law, Alben Hardwood Flooring shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use, the Website or any content on the Website.

9. Changes to Terms and Conditions
We reserve the right to make changes to these Terms and Conditions at any time. Your continued use of the Website following the posting of changes will mean that you accept and agree to the changes.

10. Contact Information
For any questions or comments regarding these Terms and Conditions, please contact us at [insert contact information].

11. Governing Law
These Terms shall be governed by and defined following the laws of the Commonwealth of Pennsylvania. Alben Hardwood Flooring and yourself irrevocably consent that the courts located in Pennsylvania shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.

12. Dispute Resolution
You agree to first attempt to resolve any dispute informally by contacting us. If we are not able to resolve the dispute informally, then either party may elect to have the dispute (except those disputes expressly excluded below) finally and exclusively resolved by binding arbitration located in Portersville, PA, unless otherwise agreed to by both parties.
`
const TermsAndConditionsPage = () => {
  const sections = [
    {
      title: 'Terms and Conditions',
      subheader: 'Please Read These Terms and Conditions Carefully',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {termsAndConditionsContent}
        </div>
      ),
    },
    // Add additional sections here if there are more parts to your terms and conditions
  ];

  return (
    <DetailPageLayout pageTitle="Terms and Conditions" sections={sections} />
  );
};

export default TermsAndConditionsPage;
