import React from "react";
import { Container, Box} from "@mui/material";
import HeroContainer from "./HeroContainer";
import ContentSection from "./contentSection";
import { Parallax } from "@react-spring/parallax";
import ParallaxFooter from "./ParallaxFooter";
import VideoHeroContainer from "./VideoHeroContainer";

const PageLayout = ({
  audioOn,
  heroVideo,
  heroImage,
  heroTitle,
  sections = [],
}) => {
  return (
    <Container style={{ maxWidth: "100%", overflowX: "hidden" }}>

      <Parallax
        pages={
          sections.length+1
        }
      >

        <HeroContainer image={heroImage} title={heroTitle} />
        <VideoHeroContainer videoSrc={heroVideo} title={heroTitle} audioOn={audioOn} />
        
        {sections.map((section, index) => (
          <ContentSection
            key={index}
            offset={
              index+1
            }
            image={section?.image}
            text={section?.text}
            isEvenSection={section?.isEvenSection}
          />
        ))}
        <ParallaxFooter offset={sections.length+1}/>
       </Parallax>
    </Container>
  );
};

export default PageLayout;
