import React, { useState } from 'react';
import bannerImage from '../assets/customer-floor-3.jpg';
import PageLayout from '../components/PageLayout';

const RoomEstimator = () => {
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [woodType, setWoodType] = useState('standard'); // 'standard' or 'premium'
  const [cutWidth, setCutWidth] = useState(5); // Default to 5" width
  const [installation, setInstallation] = useState(false);

  const calculatePrice = () => {
    let basePricePerSqFt = 4; // $4 per sqft for wood
    let installationPricePerSqFt = 4; // Additional $4 per sqft for installation
    let squareFeet = length * width;
    let totalPrice = squareFeet * basePricePerSqFt;

    // Adjust price for wood type
    if (woodType === 'premium') {
      totalPrice *= 1.2; // 20% more expensive for premium woods
    }

    // Adjust price based on cut width
    if (cutWidth < 5) {
      totalPrice *= 1.1; // 10% more for cuts smaller than 5"
    } else if (cutWidth > 5) {
      totalPrice *= 1.15; // 15% more for cuts larger than 5"
    }

    // Add installation costs if applicable
    if (installation) {
      totalPrice += squareFeet * installationPricePerSqFt;
    }

    return totalPrice.toFixed(2);
  };

  const estimatorSection = {
    content: (
      <div style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <h2>Calculate Your Flooring Cost</h2>
        <input type="number" value={length} onChange={(e) => setLength(e.target.value)} placeholder="Length (ft)" />
        <input type="number" value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Width (ft)" />
        <select value={woodType} onChange={(e) => setWoodType(e.target.value)}>
          <option value="standard">Standard Wood</option>
          <option value="premium">Premium Wood</option>
        </select>
        <input type="number" value={cutWidth} onChange={(e) => setCutWidth(e.target.value)} min={3} max={11} placeholder="Cut Width (in)" />
        <label>
          <input type="checkbox" checked={installation} onChange={(e) => setInstallation(e.target.checked)} /> Installation
        </label>
        <button onClick={() => alert(`Total Price: $${calculatePrice()}`)}>Calculate Price</button>
      </div>
    ),
    isEvenSection: false,
  };

  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Room Estimator"
      sections={[estimatorSection]}
    />
  );
};

export default RoomEstimator;
