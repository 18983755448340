import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            default: '#FFFFFF',
        },
        text: {
            primary: '#000000',
            secondary: '#555555',
        },
        primary: {
            main: '#6e4e37',
        },
        secondary: {
            main: '#9c7e64',
        },
    },
    typography: {
        fontFamily: [
            '"Lato"',
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: '"Lato", sans-serif',
            fontWeight: 700,
        },
        h2: {
            fontFamily: '"Lato", sans-serif',
            fontWeight: 700,
        },
    },
    components: {
        // Adding the MuiContainer component overrides here
        MuiContainer: {
            styleOverrides: {
                root: {
                    // Remove default padding and margin for all instances of MuiContainer
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    '@media (min-width: 600px)': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
    },
});

export default theme;
