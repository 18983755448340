import React from 'react';
import PageLayout from '../components/PageLayout';
import bannerImage from '../assets/DSC04092.JPG';
import customerFloor1 from '../assets/customer-floor-1.jpg';
import customerFloor2 from '../assets/customer-floor-2.jpg';
import customerFloor3 from '../assets/customer-floor-3.jpg';

const sections = [
  {
    image: customerFloor1,
    text: 'Our customers love the warmth and elegance added by our flooring.'
  },
  {
    image: customerFloor2,
    text: 'A perfect example of how our flooring solutions enhance a room.'
  },
  {
    image: customerFloor3,
    text: 'See how our floors can transform even the most spacious settings.'
  }
];

const Gallery = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Gallery"
      sections={sections}
    />
  );
};

export default Gallery;
