import React from 'react';
import PageLayout from '../components/PageLayout';

const FeaturedProducts = () => {
  const sections = [
    {
      image: require('../assets/DSC04114.JPG'),
      text: "Explore our handpicked selection of the finest hardwood flooring products.",
      isEvenSection: true,
    },
    {
      image: require('../assets/DSC04032.JPG'),
      text: "These standout options represent the pinnacle of craftsmanship and quality that define Alben Hardwood Flooring.",
      isEvenSection: false,
    },
    {
      image: require('../assets/00039-1492150476.png'),
      text: "We've curated a range of products that exemplify the timeless elegance and durability that our brand is renowned for.",
      isEvenSection: true,
    },
    {
      image: require('../assets/DSC04063.JPG'),
      text: "Discover the beauty of our featured products.",
      isEvenSection: false,
    },
    // Detailed sections about the specific products begin here
    {
      // Use a relevant image for each product category
      image: require('../assets/00028-2862863702.png'), 
      text: "Select & Better: Experience the harmonious blend of nature's beauty and uniform appearance with our Select & Better hardwood flooring. Ideal for spaces seeking a touch of nature's beauty without overwhelming variation. Available in lengths from 1 to 10 feet, with an average length of 38 to 45 inches.",
      isEvenSection: true,
    },
    {
      image: require('../assets/RedOak.png'),
      text: "Character Grade: Introducing our Character Grade flooring, designed to create a visually striking floor with longer planks and a rich tapestry of natural features. Ideal for those who seek a floor with high visual interest. Available in lengths from 1 to 10 feet, with an average length of 48 inches.",
      isEvenSection: false,
    },
    // Continue adding other product categories here...
  ];

  return (
    <PageLayout
      heroImage={require('../assets/DSC04062.JPG')}
      heroTitle="Featured Products"
      sections={sections}
    />
  );
};

export default FeaturedProducts;
