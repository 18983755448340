import React from 'react';
import bannerImage from '../assets/our-ceiling2.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    image: require('../assets/hickory-wall.png'),
    text: "We offer a line of solid wood paneling that gives you some spectacular alternatives to drywall and paint. Complement your flat , monochromatic walls with one finished in the depth and character of one of these beautiful forest products.",
    isEvenSection: true,
  },
  {
    image: require('../assets/oak-wall-showroom.png'),
    text: "Our wall and ceiling planks are 4 sided tongue and grooved that are designed to fit together easily, which simplifies the installation process.",
    isEvenSection: false,
  },
  {
    image: require('../assets/oakwall.png'),
    text: "Solid wood provides a genuine, authentic look and feel that cannot be replicated by wood look-alikes.  Eac piece of solid wood is unique, with its own natural grain patterns and characteristics, adding warmth and beauty to any space.",
    isEvenSection: true,
  },
];

const WallsAndCeilingsPage = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Walls and Ceilings"
      sections={sections}
    />
  );
};

export default WallsAndCeilingsPage;
