import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import AboutAlben from "./pages/aboutAlben";
import Accessories from "./pages/accessories";
import ContactUs from "./pages/contactUs";
import FeaturedProducts from "./pages/featuredProducts";
import FlooringResources from "./pages/flooringResources";
import Gallery from "./pages/gallery";
import HardwoodFlooring from "./pages/hardwoodFlooring";
import History from "./pages/history";
import HomePage from "./pages/homepage";
import HowToClean from "./pages/howToClean";
import Introduction from "./pages/introduction";
import OrderSample from "./pages/orderSample";
import OurProcess from "./pages/ourProcess";
import RoomEstimator from "./pages/roomEstimator";
import WoodTypes from "./pages/woodTypes";
import Navbar from "./components/navbar";
import theme from "./themes/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import TermsAndConditionsPage from "./pages/Terms";
import PrivacyPage from "./pages/PrivacyPage";
import InstallingAlbenHardwoods from "./pages/InstallingAlbenHardwoods";
import WallsAndCeilingsPage from "./pages/WallsAndCeilingsPage";


const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <ScrollToTop />
                <Navbar />
                <Box sx={{ pt: 8 }}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/about-alben" element={<AboutAlben />} />
                            <Route path="/accessories" element={<Accessories />} />
                            <Route path="/contact-us" element={<ContactUs />} />
                            <Route path="/featured-products" element={<FeaturedProducts />} />
                            <Route path="/flooring-resources" element={<FlooringResources />} />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route path="/hardwood-flooring" element={<HardwoodFlooring />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/how-to-clean" element={<HowToClean />} />
                            <Route path="/installation-and-care" element={<InstallingAlbenHardwoods />} />
                            <Route path="/introduction" element={<Introduction />} />
                            <Route path="/order-sample" element={<OrderSample />} />
                            <Route path="/our-process" element={<OurProcess />} />
                            <Route path="/room-estimator" element={<RoomEstimator />} />
                            <Route path="/wood-types" element={<WoodTypes />} />
                            <Route path="/terms-conditions" element={<TermsAndConditionsPage />} />
                            <Route path="/privacy-policy" element={<PrivacyPage />} />
                            <Route path="/walls-and-ceilings" element={<WallsAndCeilingsPage />} />
                            <Route path="*" element={<HomePage />} />
                        </Routes>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;
