import React from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import { Box, Typography } from "@mui/material";
import ResponsiveTypography from "./ResponsiveTypography";

const ContentSection = ({ image, text, offset }) => {
  return (
    <>
      <ParallaxLayer offset={offset} speed={0}>
        <Box
          sx={{
            position: "relative",
            height: "100vh",
            "&::before": {
              content: '""',
              backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backdropFilter: "blur(5px)", // adding the blur effect
            },
          }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={offset+.2} speed={.1}>
        <Typography
          variant="title"
          component="div"
          sx={{
            color: "white",
            fontSize: "2rem",
            maxWidth: "100%",
            textAlign: "center",
            margin: "auto",
            position: 'relative',
          }}
        >
          <ResponsiveTypography text={text} maxFont={36}/>
        </Typography>
      </ParallaxLayer>
    </>
  );
};

export default ContentSection;
