import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';
import ResponsiveTypography from './ResponsiveTypography';

const HeroContainer = ({ parallax, image, title }) => {
  if (!image && !title) {
    return null;
  }

  return (
    <>
      <ParallaxLayer offset={0} speed={-.05}>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100%',
          }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={0} speed={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {title && (
           <ResponsiveTypography title={title}/>
        )}
      </ParallaxLayer>
    </>
  );
};

export default HeroContainer;
