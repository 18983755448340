import React from 'react';
import bannerImage from '../assets/DSC03987.JPG';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    image: require('../assets/DSC04004.JPG'),
    text: "Imagine a floor that resonates with the character of your home; that's what Alben Hardwood Flooring offers.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC04071.JPG'),
    text: "Our collection, from the deep hues of Walnut to the classic warmth of Red Oak, is a palette of possibility.",
    isEvenSection: false,
  },
  {
    image: require('../assets/DSC04065.JPG'),
    text: "Whether prefinished for convenience or unfinished to put your personal touch on the final look, our floors are crafted for those who appreciate the fine art of living.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC03978.JPG'),
    text: "Let our hardwoods be the canvas upon which the story of your home unfolds.",
    isEvenSection: false,
  },
];


const HardwoodFlooring = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Our Collection"
      sections={sections}
    />
  );
};

export default HardwoodFlooring;

