import React from 'react';
import bannerImage from '../assets/DSC04004.JPG';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    image: require('../assets/1000013091.jpg'),
    text: "Elevate your hardwood flooring experience with our premium accessories.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC03920.JPG'),
    text: "From high-quality underlayment options to exquisite finishing touches, our collection is designed to not only enhance the beauty of your floors but also to provide the functionality and durability that Alben Hardwood Flooring is known for.",
    isEvenSection: false,
  },
  {
    image: require('../assets/customer-floor-1.jpg'),
    text: "Discover the perfect additions to complete your flooring project.",
    isEvenSection: true,
  },
];


const Accessories = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Accessories"
      sections={sections}
    />
  );
};

export default Accessories;


