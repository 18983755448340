import React from 'react';
import bannerImage from '../assets/DSC04092.JPG';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    image: require('../assets/20231028_125041.jpg'),
    text: "Welcome to Alben Hardwood Flooring, where timeless elegance meets unmatched durability.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC04032.JPG'),
    text: "Discover why Alben Hardwood Flooring is the choice for those who seek a perfect balance between nature's beauty and long-lasting quality.",
    isEvenSection: true,
  },
];


const Introduction = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Introduction"
      sections={sections}
    />
  );
};

export default Introduction;


