import React from 'react';
import DetailPageLayout from '../components/DetailPageLayout';

// You may have more appropriate images to use, but we'll stick with these for now
import routineCleaningImage from '../assets/00021-2490035632.png';
import expertGuidanceImage from '../assets/DSC04065.JPG';
import careRecommendationsImage from '../assets/DSC03954.JPG';

const sections = [
  {
    title: "Routine Cleaning",
    subheader: "Preserve the Elegance",
    content: "Your Alben Hardwood Floors are a centerpiece of your home's character. To maintain their natural luster, regular cleaning with gentle, hardwood-specific products is essential. We recommend a weekly regimen using microfiber mops and mild, pH-neutral cleaners to capture dirt and grime without harming the wood's delicate finish.",
    image: routineCleaningImage,
  },
  {
    title: "Expert Guidance",
    subheader: "Professional Advice",
    content: "With every Alben floor, we provide a comprehensive care manual, detailing the do's and don'ts that will help extend the life of your flooring. From tackling spills immediately to avoiding harsh chemicals, our expert advice ensures you have the know-how to protect your floors from everyday wear and tear.",
    image: expertGuidanceImage,
  },
  {
    title: "Investment Care",
    subheader: "Long-term Beauty",
    content: "Our care recommendations are designed to keep your floors looking as stunning as they were on day one. We cover everything from the right type of foot protection to the ideal humidity levels in your home. Trust our tips to safeguard your investment against potential damage and to ensure your hardwood remains a timeless attribute of your living space.",
    image: careRecommendationsImage,
  },
];

const Cleaning = () => {
  return (
    <DetailPageLayout
      pageTitle="Floor Care & Maintenance"
      sections={sections}
    />
  );
};

export default Cleaning;
