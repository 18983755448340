import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';
import ResponsiveTypography from './ResponsiveTypography';
import { useMediaQuery } from '@mui/material';

const VideoHeroContainer = ({ videoSrc, title, audioOn }) => {
    // Correcting the position of hooks to ensure they are not called conditionally
    const maxAspect = useMediaQuery('(max-aspect-ratio: 16/9)');
    const minAspect = useMediaQuery('(min-aspect-ratio: 16/9)');
    const minHeight = useMediaQuery('(max-height: 400px)');  // This variable is declared but not used, consider removing or using it

    if (!videoSrc) {
      return null;
    }

    const getYoutubeVideoId = (url) => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;  // Removed unnecessary escapes
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    };

    const videoId = getYoutubeVideoId(videoSrc);
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&showinfo=0&loop=1&playlist=${videoId}${audioOn ? '' : '&mute=1'}`;

    return (
      <>
        <ParallaxLayer offset={0} speed={-.05}>
          <iframe
            src={youtubeEmbedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              zIndex: -1,
              top: '50%',
              left: '50%',
              opacity: 0.7,
              height: minAspect ? '56.25vw' : '100vh',
              width: maxAspect ? '177.78vh' : '100vw',
              cursor: 'pointer',
              transform: 'translate(-50%, -50%)',
          }}
            title="Hero Video"  // Adding a unique title to satisfy accessibility requirements
          />
          <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.1)'
          }}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {title && (
            <ResponsiveTypography title={title} />
          )}
        </ParallaxLayer>
      </>
    );
};

export default VideoHeroContainer;
