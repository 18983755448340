import React from 'react';
import bannerImage from '../assets/DSC03942.JPG';
import PageLayout from '../components/PageLayout';


const sections = [
  {
    image: require('../assets/1000013091.jpg'),
    text: "While we are dedicated purveyors of fine hardwood flooring, we understand that the installation is just as critical to bring your vision to life.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC04114.JPG'),
    text: "That's why we offer comprehensive guidelines and support materials to ensure that your chosen professionals can seamlessly integrate our flooring into your new home.",
    isEvenSection: false,
  },
  {
    image: require('../assets/DSC03920.JPG'),
    text: "With Alben Hardwood Flooring, you purchase not just a product but a partnership.",
    isEvenSection: true,
  },
  {
    image: require('../assets/DSC03912.JPG'),
    text: "We stand by to assist your installer in creating the perfect fit for a flawless finish.",
    isEvenSection: false,
  },
];


const FlooringResources = () => {
  return (
    <PageLayout
      heroImage={bannerImage}
      heroTitle="Renew Your Floors"
      sections={sections}
    />
  );
};


export default FlooringResources;
