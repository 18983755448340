import React from 'react';
import DetailPageLayout from '../components/DetailPageLayout';

const privacyPolicyContent = `
Privacy Policy for AlbenHardwoodFlooring.com

Last Updated: 11/03/23

This Privacy Policy describes how Alben Hardwood Flooring ("we", "us", or "our"), located in Portersville, Pennsylvania, collects, uses, and shares your personal information when you use our website, AlbenHardwoodFlooring.com (the "Website").

1. Information We Collect
When you visit the Website, we may collect the following types of information:

Personal Information: Information that can be used to identify you personally, such as your name, address, email address, phone number, and payment information, when you make a purchase or create an account.
Usage Information: Information about how you use the Website and interact with us, including details of your visits to our Website and the resources that you access.
Technical Information: Information about your device or browser, including your IP address, device type, operating system, and browser type.
2. How We Use Your Information
We may use the information we collect for various purposes, including to:

Process your transactions and fulfill your orders.
Communicate with you about our products, services, and promotions.
Improve and personalize your experience on the Website.
Maintain the security of our Website.
Comply with legal obligations.
3. Sharing Your Information
We do not sell, rent, or otherwise share personal information with third parties for their marketing purposes without your consent. We may share your information with:

Service providers who perform services on our behalf, such as payment processing and delivery.
Law enforcement or other government agencies if required by law or in response to a valid request in connection with a criminal investigation or alleged illegal activity.
4. Data Security
We implement reasonable security measures designed to protect against the loss, misuse, or alteration of the information we collect.

5. Your Rights
You may have certain rights related to your personal information, including the right to access, correct, or delete the information we hold about you.

6. Children's Privacy
Our Website is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13.

7. Cookies and Tracking Technologies
We may use cookies and other tracking technologies to collect information about your browsing activities over time and across different websites following your use of the Website.

8. Changes to This Privacy Policy
We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Last Updated" date, and the updated version will be effective as soon as it is accessible.

9. Contact Us
If you have any questions or concerns about this Privacy Policy, please contact us at:

Alben Hardwood Flooring

By using our Website, you consent to the terms of this Privacy Policy.`


const PrivacyPage = () => {
  const sections = [
    {
      title: 'User Data and Privacy Guidelines',
      subheader: 'Understanding Your Privacy',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {privacyPolicyContent}
        </div>
      ),
    },
    // You can add more sections here if your privacy policy has more segments
  ];

  return (
    <DetailPageLayout pageTitle="Privacy Policy" sections={sections} />
  );
};

export default PrivacyPage;