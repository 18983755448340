import React from "react";
import PageLayout from "../components/PageLayout";
import { Box } from "@mui/material";

const InstallingAlbenHardwoods = () => {
  return (
    <Box>
       <PageLayout
        audioOn = {true}
        heroVideo='https://www.youtube.com/watch?v=cNf0hJ5pABE'
      />
    </Box>
  );
};

export default InstallingAlbenHardwoods;
